import React, { useEffect, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import Typewriter from "typewriter-effect";
// import './public/assets/js/plugins/plugin-custom'
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import Modal from 'react-modal';
import ReactCardFlip from 'react-card-flip';
// import '../public/assets/js/main.js';
import $ from "jquery";
import Tooltip from '@mui/material/Tooltip';
import ReactTooltip from 'react-tooltip';
import { json } from 'react-router-dom';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { CookiesProvider, useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import CookieConsent from "react-cookie-consent";
import { useDropzone } from 'react-dropzone';
import { Dropzone } from "dropzone";
import { GoogleLogin } from '@react-oauth/google';
var randomstring = require("randomstring");
var FormData = require('form-data');    


// import $ from 'jquery';
// window.jQuery = jQuery;

const clientId = '1075148302060-9vo78g6i085cca72ud985qchaaddsd3u.apps.googleusercontent.com';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
    },
};
export default function Privacy() {
    const [sliderRef, setSliderRef] = useState(null);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [isFlipped1, setIsFlipped1] = useState(false);
    const [isFlipped2, setIsFlipped2] = useState(false);
    const [isSidebar, setSidebar] = useState(false);
    const [data, setData] = useState([]);
    const [coins, setCoins] = useState(0);
    const [show, setShow] = useState(false);
    const [FinalModal, setForgetpasswords] = useState(false);
    const [editProfile, setProfiles] = useState(false);
    // const [file, filesname] = useState(false);
    const [profilePicture, setProfilePicture] = useState();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isConformRevealPwd, setIsConformRevealPwd] = useState(false);
    const [support, setSupport] = useState(false);
    const [supportPicture, setSupportPicture] = useState();
    const [gametrack, setGametrack] = useState(true);
    const [email, setMail] = useState('');
    const [Pwd, setPwd] = useState('');
    const [cookies, setCookie] = useCookies(['user']);
    const [isCheck, setCheck] = useState(false);
    const [activeItem, setActiveItem] = useState('home');
    const currentYear = new Date().getFullYear();


    const handleItemClick = (item) => {
        setActiveItem(item);
        console.log("setActiveItem::::::::::::", item);
    };

    useEffect(() => {
        return () => {

            if (sessionStorage.getItem("login")) {
                var logdata = {}
                logdata = sessionStorage.getItem('loginData');
                logdata = JSON.parse(logdata);
                setData(logdata);

                // $('.img-chng').click(function () {
                //     $('.run-games').html(`'<div id="iframe"><iframe src="https://www.throwcards.io/bgame?name=${logdata.data.fname}&mail=${logdata.data.email}&profile=${logdata.data.profile}&coin=${logdata.data.chips}" width="100%" height="100%" frameBorder="0" targeted=_blank allowFullScreen></iframe></div>'`);
                // });

            } else {
                console.log("not set :::::::::::::: ");
            }

            // window.postMessage('customMessageFromReact', '*');
            // window.addEventListener('message', (event) => {
            //     if (event.data === 'customMessageFromReact') {
            //         // console.log("window.addEventlistener:::::::::::::", event);
            //     }
            // });

        };
    }, []);


    const onDropZone1Drop = async (acceptedFiles) => {

        var formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        /* acceptedFiles.forEach((file) => {            
            formData.append('t', 1);  
            console.log("File is:::::::::::::;",file);
            //formData.file = file;                   
        }); */
        console.log("formData is:::::::::::::", formData)

        fetch('https://www.throwcards.io/uploads', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Process the JSON data here
                console.log("data is:::::::::::", data);
                setProfilePicture(data.imageURL);
                sessionStorage.setItem('imgUrl', data.imageURL);
                console.log("data setUploadedFiles is::::::::", data.imageURL);
            })
            .catch(error => console.error(error));
    };

    const onDropZone2Drop = async (acceptedFiles) => {

        var formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        // acceptedFiles.forEach((file) => {            
        //     formData.append("files", acceptedFiles[0]); 
        //     console.log("File is:::::::::::::;",file);
        // formData.file = acceptedFiles[0];                   
        // }); 
        console.log("acceptedFiles[0] is:::::::::::::", acceptedFiles[0]);

        fetch('https://www.throwcards.io/supportUpload', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Process the JSON data here
                console.log("data is:::::::::::", data);
                setSupportPicture(data.imageURL);
                sessionStorage.setItem('supportUrl', data.imageURL);
                console.log("data supportUrl is::::::::", data.imageURL);
            })
            .catch(error => console.log(error));
    };

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDropZone1Drop,
        accept: 'image/*',
    });
    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDropZone2Drop,
    });
    const sliderSettings = {
        // removes default buttons
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    }
    const slidertwo = {
        // removes default buttons
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    }
    const sliderthree = {
        // removes default buttons
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    }

    const termsService = (e) => {
        console.log("ishceck is::::::::::", e.target.checked);
        if (e.target.checked === true) {
            setCheck(true);
        } else {
            setCheck(false)
        }
    }

    const handleClick = (e) => {
        if (sessionStorage.getItem("login")) {
            if (window.innerWidth >= 768) {
                setIsFlipped2(!isFlipped2);

                $('.run-games').html(`'<div id="iframe"><iframe src="https://www.throwcards.io/bgame?name=${data.data.fname}&mail=${data.data.email}&profile=${data.data.profile}&coin=${data.data.chips},&GameName="bhabhiCardGame"" width="100%" height="100%" frameBorder="0" targeted=_blank allowFullScreen></iframe></div>'`);
                console.log("Data is::::::::::", data);
                

                var GameTrackingObj = {
                    gameName: 'Bhabhicardgame',
                    userName: data.data.fname,
                    uid: data.data._id,
                    chips: data.data.chips,
                    cd: moment(new Date()).format('lll')
                }

                console.log("GameTrackingObj is::::::::", GameTrackingObj);

                fetch('https://www.throwcards.io/GameTracking', {
                    method: 'POST',
                    body: JSON.stringify(GameTrackingObj),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
                .then((res) => res.json())
                .then((response) => {
                    setGametrack(response);
                    console.log("GameTracking resposne data is:::::::::", response);

                })
                .catch((err) => {
                });

            } else {
                Swal.fire({
                    title: 'Warning...!',
                    text: 'Please note that Unity WebGL is not currently supported on mobiles. Press OK if you wish to continue anyway.',
                    customClass: {
                        popup: 'custom-swal',
                    },

                }).then((response) => {
                    
                });
            }
        } else {
            setIsOpen(true)
        }

    };

    const returenFlipped = (e) => {
        setIsFlipped2(!isFlipped2);
        // setGame(false);
        //    $('#iframe').remove();
        var Gamesession = {
            gameName: gametrack.data.ops[0].gameName,
            userName: gametrack.data.ops[0].fname,
            id: gametrack.data.ops[0]._id,
            chips: gametrack.data.ops[0].chips,
            endDate: moment(new Date()).format('lll'),
            deff: 0

        }
        console.log("Game session is::::::::::", Gamesession);

        fetch('https://www.throwcards.io/Gamesession', {
            method: 'POST',
            body: JSON.stringify(Gamesession),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                console.log("resposne data is:::::::::", post);
                if (post.error !== true) {
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'incorrect',
                        text: 'Please Enter The currect Email!',
                        customClass: {
                            popup: 'Forcustome',
                        },
                    }).then((response) => {
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
            });

    }

    const handleflip = (e) => {
        e.preventDefault();
        setIsFlipped1(!isFlipped1);
    };

    Modal.setAppElement('#root');

    function openModal() {

        // fetch('https://www.throwcards.io/getLogindata')
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log("Login data is::::::::::", data);
        //         setData(data);
        //         if (data.Logintype === true) {
        //             setSidebar(true);
        //         } else {
        //             setSidebar(true);
        //         }
        //     })
        //     .catch(error => {
        //         console.error('Error fetching session data:', error);
        //     });



        if (Cookies.get('abcemil')) {
            // console.log("is set modal:::::::::::::: ");
            const cookieemail = Cookies.get('abcemil');
            const cookiepassword = Cookies.get('psdlog');
            const secretKey = 'klmnopqrstuv';
            const decryptedBytesemail = CryptoJS.AES.decrypt(cookieemail, secretKey);
            const decryptedEmail = decryptedBytesemail.toString(CryptoJS.enc.Utf8);

            const decryptedBytesPwd = CryptoJS.AES.decrypt(cookiepassword, secretKey);
            const decryptedPwd = decryptedBytesPwd.toString(CryptoJS.enc.Utf8);
            // console.log("decryptedEmail decryptedEmail::::::;;;;", decryptedEmail);
            // console.log("decryptedPwd decryptedPwd::::::;;;;", decryptedPwd);

            var getLoginObj = {
                email: decryptedEmail,
                password: decryptedPwd
            }
            console.log("getLoginObj getLoginObj::::::;;;;", getLoginObj);
            fetch('https://www.throwcards.io/getLogindata', {
                method: 'POST',
                body: JSON.stringify(getLoginObj),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((res) => res.json())
                .then((post) => {
                    console.log("resposne data is:::::::::", post);
                    if (post.error !== true) {
                        // setData(post.data);                    
                        sessionStorage.setItem('login', true);
                        sessionStorage.setItem('loginData', JSON.stringify(post));
                        setData(post);
                        setCoins({ chips: post.data.myInfo.chip });
                        console.log("post.data is:::::::::::", data);
                        // window.location.reload();
                        setSidebar(true);
                    } else {
                        console.log("cpass n pass error");
                        Swal.fire({
                            icon: 'error',
                            title: 'incorrect',
                            text: 'Please Enter The username and Password is currect!'
                        }).then((response) => {

                        });
                    }
                })
                .catch((err) => {
                });



        } else {
            console.log("not set modal:::::::::::::: ");
            setIsOpen(true);
        }
    }
    function afterOpenModal() {

    }
    function closeModal() {
        setIsOpen(false);
    }
    const closeSidebar = (e) => {
        setSidebar(false);
    }
    const forgetpassword = (e) => {
        setShow(true);
    }
    const closeforget = (e) => {
        setShow(false);
    }
    const closeforgetfinalmodal = (e) => {
        setForgetpasswords(false);
    }
    const ForgetSubmit = (e) => {
        e.preventDefault();
        var ForgetData = new FormData(e.target);
        var ForgetDataobject = Object.fromEntries(ForgetData.entries());
        ForgetDataobject.token = randomstring.generate(16);
        ForgetDataobject.url = 'https://www.throwcards.io/userForgetpassword?email=${ForgetDataobject.email}&token=${ForgetDataobject.token}&url=${ForgetDataobject.url}';

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes,',
            customClass: {
                popup: 'Forcustome',
            },
        }).then((result) => {

            if (result.isConfirmed) {

                fetch(`https://www.throwcards.io/userForgetpassword?email=${ForgetDataobject.email}&token=${ForgetDataobject.token}&url=${ForgetDataobject.url}`, {
                    method: 'Get',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((res) => res.json())
                    .then((post) => {
                        console.log("post userForgetpassword:::::::::", post)
                        if (post.error === false) {
                            var data = post.data.ops[0];
                            sessionStorage.setItem("emailId", data.email);
                            // setData(postdata);
                            sessionStorage.setItem("token", data.token);
                            setForgetpasswords(true);
                        }
                    })
            } else {
                window.location.reload();
            }

        })

    }
    const FinalForgetSubmite = (e) => {
        e.preventDefault();
        const FinalForgetData = new FormData(e.target);
        const FinalForgetDataObject = Object.fromEntries(FinalForgetData.entries());
        console.log("FinalForgetDataObject LoginData:::::::::::::>>>", FinalForgetDataObject);
        var mail = sessionStorage.getItem("emailId");
        var token = sessionStorage.getItem("token");
        FinalForgetDataObject.mail = mail;
        FinalForgetDataObject.token = token;
        if (FinalForgetDataObject.npassword === FinalForgetDataObject.cpassword) {
            fetch('https://www.throwcards.io/ForgetPasswords', {
                method: 'POST',
                body: JSON.stringify(FinalForgetDataObject),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((res) => res.json())
                .then((post) => {
                    console.log("resposne data is:::::::::", post);
                    if (post.error !== true) {
                        Swal.fire(
                            'Congretulatios!',
                            'Your Password Updated..',
                            'success'
                        ).then((response) => {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'incorrect',
                            text: 'Please Enter The currect Email!',
                            customClass: {
                                popup: 'Forcustome',
                            },
                        }).then((response) => {
                            window.location.reload();
                        });
                    }
                })
                .catch((err) => {
                });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'incorrect',
                text: 'Please Enter The Same Password!'
            }).then((response) => {
            });
        }

    }
    const onSubmit = (e) => {
        const secretKey = 'klmnopqrstuv';
        const encryptedEmail = CryptoJS.AES.encrypt(email, secretKey).toString();
        const encryptedPwd = CryptoJS.AES.encrypt(Pwd, secretKey).toString();
        setCookie('abcemil', encryptedEmail, { path: '/' });
        setCookie('psdlog', encryptedPwd, { path: '/' });
        e.preventDefault();
        const LoginData = new FormData(e.target);
        const LoginDataObject = Object.fromEntries(LoginData.entries());
        console.log("LoginData:::::::::::::>>>", LoginDataObject);

        fetch('https://www.throwcards.io/userLogin', {
            method: 'POST',
            body: JSON.stringify(LoginDataObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then(post => {
                // Update the state with the API response.
                if (post.error !== true) {
                    // setData(post.data);                    
                    sessionStorage.setItem('login', true);
                    sessionStorage.setItem('loginData', JSON.stringify(post));
                    setData(post);
                    console.log("post.data is:::::::::::", data);
                    window.location.reload();
                } else {
                    console.log("cpass n pass error");
                    Swal.fire({
                        icon: 'error',
                        title: 'incorrect',
                        text: 'Please Enter The username and Password is currect!'
                    }).then((response) => {

                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    const onSubmitRegister = (e) => {
        e.preventDefault();
        const LoginData = new FormData(e.target);
        const RegisterDataObject = Object.fromEntries(LoginData.entries());
        RegisterDataObject.coins = 1000;
        RegisterDataObject.profile = '/assets/images/product-img-001.png';
        console.log("LoginData:::::::::::::>>>", RegisterDataObject);
        fetch('https://www.throwcards.io/userRegister', {
            method: 'POST',
            body: JSON.stringify(RegisterDataObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                if (post.error === true) {
                    console.log("cpass n pass error");
                    Swal.fire({
                        icon: 'error',
                        title: 'Password not match',
                        text: 'Please Enter The Same Password in Confrom Password!'
                    })
                } else {
                    var loging = post.isloggedIn;
                    console.log("resposne data is:::::::::", loging);
                    sessionStorage.setItem("isLoggedIn", loging);
                    window.location.reload();
                    setIsOpen(true);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    const logout = (response) => {
        var obj = {};
        obj = sessionStorage.getItem("loginData");        
        fetch('https://www.throwcards.io/userLogout', {
            method: 'POST',
            body: obj,
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                sessionStorage.clear();
                window.location.reload();
                Cookies.remove('abcemil');
                Cookies.remove('psdlog');
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    const ProfileDataSubmite = (e) => {
        e.preventDefault();
        const Profiledata = new FormData(e.target);
        const ProfileDataObject = Object.fromEntries(Profiledata.entries());
        var img = sessionStorage.getItem('imgUrl');
        ProfileDataObject.profile = img;
        console.log("::ProfileDataObject:::::::::::", ProfileDataObject);

        fetch('https://www.throwcards.io/updateProfile', {
            method: 'POST',
            body: JSON.stringify(ProfileDataObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                console.log("resposne data is:::::::::", post);
                if (post.error !== true) {
                    var updateddata = post;
                    setData(updateddata);
                    sessionStorage.setItem('loginData', JSON.stringify(post));
                    Swal.fire(
                        'Congratulations!',
                        'Your Profile Has Been Updated..',
                        'success'
                    ).then((response) => {
                        console.log("setdata respose swl is:::::::::", response);
                        window.location.reload();
                    });
                } else {
                    console.log("cpass n pass error");
                    Swal.fire({
                        icon: 'error',
                        title: 'incorrect',
                        text: 'Please Enter The username and Password is currect!'
                    }).then((response) => {

                    });
                }

            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const handleSupport = (e) => {
        setSupport(true);
    }
    const closesupport = (e) => {
        setSupport(false);
    }

    const supportSubmit = (e) => {
        e.preventDefault();
        const supporData = new FormData(e.target);
        const supportObject = Object.fromEntries(supporData.entries());
        var suppFile = sessionStorage.getItem('supportUrl');
        supportObject.supportfile = suppFile;
        console.log("::supportObject:::::::::::", supportObject);

        fetch('https://www.throwcards.io/suportData', {
            method: 'POST',
            body: JSON.stringify(supportObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                console.log("resposne data is:::::::::", post);
                if (post.error !== true) {
                    Swal.fire(
                        'Congratulations!',
                        'Your Message Has Been Send',
                        'success'
                    ).then((response) => {
                        console.log("setdata respose swl is:::::::::", response);
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    const failedGoogle = (response) => {
        // if (response.profileObj) {

        // }else{
        //     console.log("Error Google Login:::::::::");
        // }
        console.log("failedGoogle Google Login:::::::::", response);
    }
    const sucessGoogle = (response) => {
        // if (response.profileObj) {

        // }else{
        //     console.log("Error Google Login:::::::::");
        // }
        console.log("sucessGoogle Google Login:::::::::", response);
    }
    const handleEdit = (e) => {
        fetch('https://www.throwcards.io/getLogindata')
            .then(response => response.json())
            .then(data => {
                console.log("data.data.profiles::::::::::", data.data.profile);
                setData(data);
                // setFiles({name: data.data.profile});
                // console.log("setFiles.name is::::::::::", files);
            })
            .catch(error => {
                console.error('Error fetching session data:', error);
            });
        setProfiles(true);

    }
    const closeEditprofile = (e) => {
        setProfiles(false);
    }

    const onSubmitContact = (e) => {
        e.preventDefault();
        const Contactdata = new FormData(e.target);
        const ContactDataObject = Object.fromEntries(Contactdata.entries());
        console.log(":::ContactDataObject:::::::::::::::", ContactDataObject);

        fetch('https://www.throwcards.io/contactus', {
            method: 'POST',
            body: JSON.stringify(ContactDataObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                console.log("resposne data is:::::::::", post);
                if (post.error !== true) {
                    Swal.fire(
                        'Congretulatios!',
                        'Your Message has been Send',
                        'success'
                    ).then((response) => {
                        window.location.reload();
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'incorrect',
                        text: 'Please Enter The currect Email!',
                        customClass: {
                            popup: 'Forcustome',
                        },
                    }).then((response) => {
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
            });

    }

    // var logintype = sessionStorage.getItem('login');
    // console.log("logitype is::::::::::", logintype)
    return (

        <div>

            <Helmet>
                {/* <script src='../assets/js/main.js'></script> */}
            </Helmet>


            <div className="App">

                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="log-reg cmn-popup">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="modal fade" id="loginMod" >
                                        <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                    <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                        aria-label="Close" onClick={closeModal}>
                                                        <i className="material-symbols-outlined fs-two"> close </i>
                                                    </button>
                                                </div>
                                                <div className="tab-content flipper" id="flipper">
                                                    <ReactCardFlip isFlipped={isFlipped1} flipDirection="vertical">
                                                        <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                            aria-labelledby="loginArea-tab">
                                                            <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                                <li className="bottom-area" role="presentation">
                                                                    <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                                        data-bs-target="#loginArea" type="button" role="tab"
                                                                        aria-controls="loginArea" aria-selected="true">
                                                                        Login
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            <div className="login-content">
                                                                <div className="modal-body p-0">
                                                                    <form onSubmit={onSubmit} id="formdata" action="#" className="p-4 p-sm-8">
                                                                        <p>Sign up for faster checkout, special offers, and customer
                                                                            support!</p>
                                                                        <div className="form-inside mt-6 p-3 p-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="loginemail">Email*</label>
                                                                                        <input type="email" id="loginemail" name="email" value={email}
                                                                                            placeholder="Enter your email"  onChange={(e) => setMail(e.target.value)} autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="loginpassword">Password*</label>
                                                                                        <div className="pass-box d-center position-relative">
                                                                                        <input type={isRevealPwd ? "text" : "password"} id="loginpassword" name="password" value={Pwd}  placeholder="Enter your Password" onChange={(e) => setPwd(e.target.value)} autoComplete="off" required />

                                                                                            {isRevealPwd ? <i
                                                                                                className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                                                                                visibility </i> : <i
                                                                                                    className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                                                                                visibility_off</i>}

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex justify-content-start'>
                                                                                    <a style={{ color: "#dff00e" }} onClick={forgetpassword}>Forget Your Password ?</a>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="checkbox-item d-grid gap-4 mt-4 mt-sm-6">
                                                                                        <label
                                                                                            className="single-radio checkbox position-relative d-flex align-items-center px-5 ps-8">
                                                                                            <span className="text-start d-flex gap-1">
                                                                                                I agree to the <a href='/terms&condition'  style={{color:   '#dff00e', fontWeight: "600"}} target='_blank'>Terms of
                                                                                                    Services</a>
                                                                                            </span>
                                                                                            <input type="checkbox" name="agree" onChange={termsService} />
                                                                                            <span className="checkmark start-0"></span>
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                        <button className="box-style btn-box">
                                                                                            Login
                                                                                        </button>
                                                                                    </div>
                                                                                    {/* <p className="text-center" style={{marginLeft: "30px", lineHeight: "3"}}>Or</p>                                                                     */}
                                                                                    {/* <div className='modal-footer position-relative d-flex justify-content-start'> */}

                                                                                    {/* <div className="btn-area mt-2 mt-sm-5">
                                                                                        <button className="box-style btn-box">
                                                                                            Google Login
                                                                                        </button>
                                                                                    </div> */}


                                                                                    {/* <GoogleLogin
                                                                                            clientId={clientId}
                                                                                            buttonText="Login with Google"
                                                                                            onSuccess={sucessGoogle}
                                                                                            onFailure={failedGoogle}
                                                                                            cookiePolicy={'single_host_origin'}
                                                                                        /> */}
                                                                                    {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}

                                                                                    {/* <FacebookLogin
                                                                                            style={{
                                                                                                backgroundColor: '#4267b2',
                                                                                                color: '#fff',
                                                                                                fontSize: '16px',
                                                                                                padding: '12px 24px',
                                                                                                border: 'none',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                            appId="1394690131257414"
                                                                                            onSuccess={(response) => {
                                                                                                console.log('Login Success!', response);
                                                                                            }}
                                                                                            onFail={(error) => {
                                                                                                console.log('Login Failed!', error);
                                                                                            }}
                                                                                            onProfileSuccess={(response) => {
                                                                                                console.log('Get Profile Success!', response);
                                                                                            }}
                                                                                        /> */}

                                                                                    {/* <FacebookLogin
                                                                                            appId="1394690131257414"
                                                                                            autoLoad={false}
                                                                                            fields="name,email,picture"
                                                                                            onSuccess={responseFacebook}
                                                                                        /> */}
                                                                                    {/* <a href='#!'>
                                                                                            <img src='assets/images/login/facebook button_.png' className='mt-5' width={200} alt='img' />
                                                                                        </a> */}
                                                                                    {/* </div> */}
                                                                                  
                                                                                </div>
                                                                                <div className='text-center' style={{color: "#dff00e"}}>
                                                                                You don't have an account yet!
                                                                                    <a href='#!' onClick={handleflip} className="flipbutton"  style={{lineHeight: "0"}}id="loginButton"><strong> Register Now</strong></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    
                                                                    {/* <a href='https://www.throwcards.io/facebook'>
                                                                        <div className="btn-area facebook-btn mt-2 mt-sm-5">
                                                                            <button className="box-style btn-box">
                                                                                Facebook Login
                                                                            </button>
                                                                        </div>
                                                                    </a> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className=" back tab-pane fade" id="regArea" role="tabpanel"
                                                            aria-labelledby="regArea-tab">
                                                            <ul className="nav flex-nowrap log-reg-btn justify-content-center">

                                                                <li className="bottom-area" role="presentation">
                                                                    <button className="nav-link py-4 active" id="regArea-tab" data-bs-toggle="tab"
                                                                        data-bs-target="#regArea" type="button" role="tab"
                                                                        aria-controls="regArea" aria-selected="false">
                                                                        Sign Up
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            <div className="reg-content regMod">
                                                                <div className="modal-body p-0">
                                                                    <form onSubmit={onSubmitRegister} action="#" className="p-4 p-sm-8">
                                                                        <p>Sign up for faster checkout, special offers, and customer
                                                                            support!</p>
                                                                        <div className="form-inside mt-6 p-3 p-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="fname">First Name*</label>
                                                                                        <input type="text" name='fname' id="fname" placeholder="Enter Your First Name"
                                                                                            autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="lname">Last Name*</label>
                                                                                        <input type="text" id="lname" name='lname'
                                                                                            placeholder="Enter Your Last Name"
                                                                                            autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="email">Email*</label>
                                                                                        <input type="email" id="email" name='email'
                                                                                            placeholder="Enter your email"
                                                                                            autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="password">Password*</label>
                                                                                        <div className="pass-box d-center position-relative">
                                                                                            <input type={isRevealPwd ? "text" : "password"} id="password" name="password" placeholder="Enter your Password" autoComplete="off" required />
                                                                                            {isRevealPwd ? <i
                                                                                                className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                                                                                visibility </i> : <i
                                                                                                    className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                                                                                visibility_off</i>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="cpassword">Confirm Password*</label>
                                                                                        <div className="pass-box d-center position-relative">
                                                                                            <input type={isConformRevealPwd ? "text" : "password"} id="cpassword" name="cpassword" placeholder="Enter your Password" autoComplete="off" required />
                                                                                            {isConformRevealPwd ? <i
                                                                                                className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsConformRevealPwd(prevState => !prevState)}>
                                                                                                visibility </i> : <i
                                                                                                    className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsConformRevealPwd(prevState => !prevState)}>
                                                                                                visibility_off</i>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="checkbox-item d-grid gap-4 mt-4 mt-sm-6">
                                                                                        <label
                                                                                            className="single-radio checkbox position-relative d-flex align-items-center px-5 ps-8 justify-content-between">
                                                                                           <span className="text-start d-flex gap-1">
                                                                                                I agree to the <a href='/terms&condition'  style={{color:   '#dff00e', fontWeight: "600"}} target='_blank'>Terms of
                                                                                                    Services</a>
                                                                                            </span>
                                                                                            <span className="flipbutton" onClick={handleflip} id="registerButton" >
                                                                                                Back to login →
                                                                                            </span>
                                                                                            <input type="checkbox" name="agree" onChange={termsService} />
                                                                                            <span className="checkmark start-0"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="btn-area mt-4 mt-sm-8">
                                                                                        {
                                                                                            isCheck === true ?
                                                                                                <button className="box-style btn-box">
                                                                                                    Sign Up
                                                                                                </button> :
                                                                                                <button className="box-style btn-box" disabled>
                                                                                                    Sign Up
                                                                                                </button>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ReactCardFlip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>


                <Modal
                    isOpen={show}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeforget}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="log-reg cmn-popup">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="modal fade" id="loginMod" >
                                        <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                    <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                        aria-label="Close" onClick={closeforget}>
                                                        <i className="material-symbols-outlined fs-two"> close </i>
                                                    </button>
                                                </div>
                                                <div className="tab-content flipper" id="flipper">

                                                    <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                        aria-labelledby="loginArea-tab">
                                                        <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                            <li className="bottom-area" role="presentation">
                                                                <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                                    data-bs-target="#loginArea" type="button" role="tab"
                                                                    aria-controls="loginArea" aria-selected="true">
                                                                    Forget Password
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <div className="login-content">
                                                            <div className="modal-body p-0">
                                                                <form onSubmit={ForgetSubmit} id="formdata" action="#" className="p-4 p-sm-8">
                                                                    <p>Sign up for faster checkout, special offers, and customer
                                                                        support!</p>
                                                                    <div className="form-inside mt-6 p-3 p-sm-6">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Email*</label>
                                                                                    <input type="email" id="loginemail" name="email"
                                                                                        placeholder="Enter your email"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                    <button className="box-style btn-box">
                                                                                        Send mail
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal
                    isOpen={FinalModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeforgetfinalmodal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="log-reg cmn-popup">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="modal fade" id="loginMod" >
                                        <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                    <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                        aria-label="Close" onClick={closeforgetfinalmodal}>
                                                        <i className="material-symbols-outlined fs-two"> close </i>
                                                    </button>
                                                </div>
                                                <div className="tab-content flipper" id="flipper">

                                                    <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                        aria-labelledby="loginArea-tab">
                                                        <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                            <li className="bottom-area" role="presentation">
                                                                <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                                    data-bs-target="#loginArea" type="button" role="tab"
                                                                    aria-controls="loginArea" aria-selected="true">
                                                                    Enter a Password
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <div className="login-content">
                                                            <div className="modal-body p-0">
                                                                <form onSubmit={FinalForgetSubmite} id="formdata" action="#" className="p-4 p-sm-8">
                                                                    <p>Sign up for faster checkout, special offers, and customer
                                                                        support!</p>
                                                                    <div className="form-inside mt-6 p-3 p-sm-6">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">New Password</label>
                                                                                    <input type="text" id="npassword" name="npassword"
                                                                                        placeholder="Enter your new password"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Confirm Password</label>
                                                                                    <input type="text" id="cpassword" name="cpassword"
                                                                                        placeholder="Enter your Confirm Password"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                    <button className="box-style btn-box">
                                                                                        Save
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                {editProfile && (
                    <Modal
                        isOpen={editProfile}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeEditprofile}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="log-reg cmn-popup edit-profile">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="modal fade" id="loginMod" >
                                            <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                        <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                            aria-label="Close" onClick={closeEditprofile}>
                                                            <i className="material-symbols-outlined fs-two"> close </i>
                                                        </button>
                                                    </div>
                                                    <div className="tab-content flipper" id="flipper">
                                                        <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                            aria-labelledby="loginArea-tab">
                                                            <div className="login-content">
                                                                <div className="modal-body p-0">
                                                                    <form onSubmit={ProfileDataSubmite} id="formdata" action="#" className="p-4 p-sm-8">
                                                                        <div className="avatar-upload">
                                                                            <div {...getRootProps1()} className="dropzone avatar-edit">
                                                                                <input {...getInputProps1()} type='file' id="" />
                                                                                <label htmlFor="imageUpload"></label>
                                                                                <p>Drag & drop files here, or click to select files</p>

                                                                            </div>
                                                                            <div className="avatar-preview">
                                                                                <div id="imagePreview" style={{ backgroundImage: `url(${profilePicture ? profilePicture : data.data.profile})`, backgroundSize: "cover" }}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-inside p-3 p-sm-6" style={{ marginTop: "-100px" }}>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <input type="text" id="id" name="id" defaultValue={data.data._id} placeholder="Enter your First Name" autoComplete="off" hidden />
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="loginemail">First Name</label>
                                                                                        <input type="text" id="fname" name="fname" defaultValue={data.data.fname} placeholder="Enter your First Name" autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="loginemail">Last Name</label>
                                                                                        <input type="text" id="lname" name="lname" defaultValue={data.data.lname} placeholder="Enter your Last Name" autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                        <button className="box-style btn-box">
                                                                                            Save
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                )}

                <Modal
                    isOpen={support}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closesupport}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="log-reg cmn-popup">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="modal fade" id="loginMod" >
                                        <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                    <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                        aria-label="Close" onClick={closesupport}>
                                                        <i className="material-symbols-outlined fs-two"> close </i>
                                                    </button>
                                                </div>
                                                <div className="tab-content flipper" id="flipper">

                                                    <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                        aria-labelledby="loginArea-tab">
                                                        <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                            <li className="bottom-area" role="presentation">
                                                                <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                                    data-bs-target="#loginArea" type="button" role="tab"
                                                                    aria-controls="loginArea" aria-selected="true">
                                                                    Support
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <div className="login-content">
                                                            <div className="modal-body p-0">
                                                                <form onSubmit={supportSubmit} id="formdata" action="#" className="p-4 p-sm-8">

                                                                    <div className="form-inside mt-6 p-3 p-sm-6">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Email</label>
                                                                                    <input type="text" id="email" name="email"
                                                                                        placeholder="Enter your Email"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Subject</label>
                                                                                    <input type="text" id="subject" name="subject"
                                                                                        placeholder="Enter your Subject"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Game Name</label>
                                                                                    <input type="text" id="gname" name="gname"
                                                                                        placeholder="Enter your Game Name"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="avatar-uploads dropzones">
                                                                                    <div {...getRootProps2()} className="dropzones avatar-edit">
                                                                                        <input {...getInputProps2()} type='file' id="" />
                                                                                        <label htmlFor="imageUpload"></label>
                                                                                        <p>Drag & drop files here, or click to select files</p>
                                                                                    </div>
                                                                                    <div className="avatar-preview">
                                                                                        <div id="imagePreview">
                                                                                            <img src={supportPicture ? supportPicture : ''} width={150} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                    <button className="box-style btn-box">
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                {/* <div className="preloader align-items-center justify-content-center">
                    <span className="loader"></span>
                </div> */}
                <button className="scrollToTop d-md-flex d-center" aria-label="scroll Bar Button">Back To Top</button>
                <div className="follower"></div>
                {/* <header className="header-section header-menu">
                    <nav className="navbar w-100 flex-nowrap px-2 py-6 ps-2 ps-xl-10 ps-xxl-10 navbar-expand-xl">
                        <div className="sidebar-close mobile-menu">
                            <button className="d-center d-grid d-xl-none">
                                <i className="material-symbols-outlined mat-icon fs-four"> menu_open </i>
                                <span className="fs-six">MENU</span>
                            </button>
                        </div>
                        <a href="#!" className="navbar-brand ms-4 ms-xxl-20 d-flex align-items-center gap-2">
                            <img src="assets/images/fav.png" className="logo" alt="logo" />
                            <img src="assets/images/throw_cards.pngg" className="logo-text d-xxl-block d-none" alt="logo-text" />
                        </a>
                        <div className="collapse navbar-collapse d-flex gap-10 w-100 justify-content-end" id="navbar-content">
                            <div className="contact-info ms-xl-0 ms-xxl-5 d-none d-sm-flex align-items-center gap-2">
                                <i className="material-symbols-outlined mat-icon"> smartphone </i>
                                <span>(302) 555-0107</span>
                            </div>
                            <ul className="navbar-nav d-xl-flex d-none gap-6 py-4 py-lg-0 m-auto p-20 align-self-center">
                                <li className={activeItem === 'home' ? 'active' : ''}>
                                    <a onClick={() => handleItemClick('home')} className='dropdown-nav' href={"/"}>Home</a>
                                </li>
                                <li className={activeItem === 'store' ? 'active' : ''}>
                                    <a onClick={() => handleItemClick('store')} className="dropdown-nav" href={"/store"}>Store</a>
                                </li>
                                <li className={activeItem === 'about' ? 'active' : ''}>
                                    <a onClick={() => handleItemClick('about')}  className="dropdown-nav" href="#!">About</a>
                                </li>
                                <li className={activeItem === 'contact' ? 'active' : ''}>
                                    <a onClick={() => handleItemClick('contact')}  className="dropdown-nav" href="#contact">Contact</a>
                                </li>                               
                            </ul>
                            <div className="right-area position-relative d-flex gap-3 gap-xxl-6 align-items-center">
                              
                                <div className="cmn-head" style={{ cursor: "inherit" }}>
                                    {data.error === false ? <span>{data.data.chips}</span> : ''}
                                </div>

                                <div className="cmn-head">
                                    <Tooltip title="Profile" arrow>
                                        <button type="button" className="icon-area d-center position-relative" id='showmodal' data-bs-toggle="modal"
                                            data-bs-target="#loginMod" onClick={openModal}>
                                            <i className="material-symbols-outlined mat-icon fs-five">person</i>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="cmn-head">
                                    {data.error === false ?
                                        <Tooltip title="LogOut" arrow>
                                            <button type="button" className="icon-area d-center position-relative" id='showmodal' data-tip="This is a tooltip" data-width="200" onClick={logout}>
                                                <i className="material-symbols-outlined mat-icon fs-five">Logout</i>
                                            </button>
                                        </Tooltip>
                                        : ''}
                                </div>
                                {isSidebar && (
                                    <div className="single-item cart-area active">
                                        <div className="nav-items-wrapper d-flex flex-column p-4 p-sm-7 justify-content-between"  >
                                            <button className="position-absolute close-btn">
                                                <i className="material-symbols-outlined mat-icon fs-three" onClick={closeSidebar}>close</i>
                                            </button>
                                            <div className="nav-items ">
                                                <h4 className="mb-7">Your Profiles</h4>
                                                <div className="single-box p-2">
                                                    <div className="d-flex gap-2 justify-content-between align-items-start">
                                                        <div className="content-box d-flex">
                                                            <div className="icon-box d-inline-flex d-center" style={{ border: "4px solid #dff00e", borderRadius: "50px" }}>
                                                                <img src={data.data.profile} alt="icon" />
                                                            </div>
                                                            <div className="info-box">
                                                                <a href="#!">
                                                                    <h5>{data.data.fname} {data.data.lname}</h5>
                                                                </a>
                                                                <span>{data.data.chips}</span>
                                                                <a href='#!'>
                                                                    <p>{data.data.email}</p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <button type="button" className="end-area">
                                                            <i className="material-symbols-outlined pe-3 fs-six" onClick={handleEdit}>edit</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </nav>
                </header> */}
                <div className="sidebar-wrapper">
                    <div className="position-relative">
                        {/* <div className="side-menubar py-6 d-flex flex-column justify-content-between align-items-center">
                            <div className="sidebar-close d-none d-xl-block">
                                <button className="d-center d-grid">
                                    <i className="material-symbols-outlined mat-icon fs-three"> menu_open </i>
                                    <span>MENU</span>
                                </button>
                            </div>
                            <ul className="d-grid gap-4 social-area mt-10">
                                <li>
                                    <a href="https://www.facebook.com/" aria-label="Facebook" className="d-center">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" aria-label="Instagram" className="d-center">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" aria-label="Linkedin" className="d-center">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" aria-label="Twitter" className="d-center">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                            </ul>
                            <div className="bottom-area">
                            </div>
                        </div> */}
                        <div className="sidebar-content d-center flex-columnn">
                            <div className="header-section d-block">
                                <div className="navbar bg-transparent">
                                    <ul className="navbar-nav d-xl-flex gap-2 gap-md-5 py-4 py-lg-0 px-4 px-lg-0 align-self-center mobile-dev">
                                        <li>
                                            <a className="dropdown-nav" style={{fontSize: "22px"}} href="#!">Home</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-nav" style={{fontSize: "22px"}} href="/store">Store</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-nav" style={{fontSize: "22px"}} href="/about">About</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-nav" style={{fontSize: "22px"}} href="#contact">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-items w-100 bottom-0">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Office</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> location_on </i>
                                                </div>
                                                <div className="right-item w-75">
                                                    <h5>London</h5>
                                                    <span>Al. Brucknera Aleksandra 63, Wrocław 51-410</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Email address</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> mail </i>
                                                </div>
                                                <div className="right-item d-grid">
                                                    <span><a href="https://pixner.net/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="65201d0408150900250208040c094b060a08">[email&#160;protected]</a></span>
                                                    <span><a href="https://pixner.net/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="793c01181409151c391e14181015571a1614">[email&#160;protected]</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Phone Number</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> call </i>
                                                </div>
                                                <div className="right-item d-grid">
                                                    <span>(302) 555-0107</span>
                                                    <span>(302) 555-0107</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Working Hours</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> schedule </i>
                                                </div>
                                                <div className="right-item w-50">
                                                    <span>Mon-Fri: 09: 00-18: 00 Sat-Sun: Weekend</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             
                <div className="log-reg cmn-popup">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="modal fade" id="loginMod">
                                    <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                <button type="button" className="btn-close p-0" data-bs-dismiss="modal"
                                                    aria-label="Close">
                                                    <i className="material-symbols-outlined fs-two"> close </i>
                                                </button>
                                            </div>
                                            <div className="tab-content">
                                                <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                    <li className="bottom-area" role="presentation">
                                                        <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                            data-bs-target="#loginArea" type="button" role="tab"
                                                            aria-controls="loginArea" aria-selected="true">
                                                            Login
                                                        </button>
                                                    </li>
                                                    <li className="bottom-area" role="presentation">
                                                        <button className="nav-link py-4" id="regArea-tab" data-bs-toggle="tab"
                                                            data-bs-target="#regArea" type="button" role="tab"
                                                            aria-controls="regArea" aria-selected="false">
                                                            Sign Up
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div className="tab-pane fade show active" id="loginArea" role="tabpanel"
                                                    aria-labelledby="loginArea-tab">
                                                    <div className="login-content">
                                                        <div className="modal-body p-0">
                                                            <form action="#" className="p-4 p-sm-8">
                                                                <p>Sign up for faster checkout, special offers, and customer
                                                                    support!</p>
                                                                <div className="form-inside mt-6 p-3 p-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <div className="single-input text-start">
                                                                                <label htmlFor="loginemail">Email*</label>
                                                                                <input type="text" id="loginemail"
                                                                                    placeholder="Enter your email"
                                                                                    autoComplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="single-input text-start">
                                                                                <label htmlFor="loginpassword">Password*</label>
                                                                                <div className="pass-box d-center position-relative">
                                                                                    <input type="text" id="loginpassword"
                                                                                        placeholder="Enter your Password"
                                                                                        autoComplete="off" />
                                                                                    <i
                                                                                        className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4">
                                                                                        visibility </i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="checkbox-item d-grid gap-4 mt-4 mt-sm-6">
                                                                                <label
                                                                                    className="single-radio checkbox position-relative d-flex align-items-center px-5 ps-8">
                                                                                    <span className="text-start d-flex gap-1">
                                                                                        I agree to the <a href='/terms&condition'  style={{color:   '#dff00e', fontWeight: "600"}} target='_blank'>Terms of
                                                                                            Services</a>
                                                                                    </span>
                                                                                    <input type="checkbox" name="agree" onChange={termsService} />
                                                                                    <span className="checkmark start-0"></span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="btn-area mt-4 mt-sm-8">
                                                                                {
                                                                                    isCheck === true ?
                                                                                        <button className="box-style btn-box">
                                                                                            Sign Up
                                                                                        </button> :
                                                                                        <button className="box-style btn-box" disabled>
                                                                                            Sign Up
                                                                                        </button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="regArea" role="tabpanel"
                                                    aria-labelledby="regArea-tab">
                                                    <div className="reg-content regMod">
                                                        <div className="modal-body p-0">
                                                            <form action="#" className="p-4 p-sm-8">
                                                                <p>Sign up for faster checkout, special offers, and customer
                                                                    support!</p>
                                                                <div className="form-inside mt-6 p-3 p-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <div className="single-input text-start">
                                                                                <label htmlFor="fname">First Name*</label>
                                                                                <input type="text" id="fname"
                                                                                    placeholder="Enter Your First Name"
                                                                                    autoComplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="single-input text-start">
                                                                                <label htmlFor="lname">Last Name*</label>
                                                                                <input type="text" id="lname"
                                                                                    placeholder="Enter Your Last Name"
                                                                                    autoComplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="single-input text-start">
                                                                                <label htmlFor="email">Email*</label>
                                                                                <input type="text" id="email"
                                                                                    placeholder="Enter your email"
                                                                                    autoComplete="off" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="single-input text-start">
                                                                                <label htmlFor="password">Password*</label>
                                                                                <div className="pass-box d-center position-relative">
                                                                                    <input type="text" id="password"
                                                                                        placeholder="Enter your Password"
                                                                                        autoComplete="off" />
                                                                                    <i
                                                                                        className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4">
                                                                                        visibility </i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="single-input text-start">
                                                                                <label htmlFor="cpassword">Confirm Password*</label>
                                                                                <div className="pass-box d-center position-relative">
                                                                                    <input type="text" id="cpassword"
                                                                                        placeholder="Enter your Password"
                                                                                        autoComplete="off" />
                                                                                    <i
                                                                                        className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4">
                                                                                        visibility </i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="checkbox-item d-grid gap-4 mt-4 mt-sm-6">
                                                                                <label
                                                                                    className="single-radio checkbox position-relative d-flex align-items-center px-5 ps-8">
                                                                                    <span className="text-start d-flex gap-1">
                                                                                        I agree to the <a href='/terms&condition'  style={{color:   '#dff00e', fontWeight: "600"}} target='_blank'>Terms of
                                                                                            Services</a>
                                                                                    </span>
                                                                                    <input type="checkbox" name="agree" onChange={termsService} />
                                                                                    <span className="checkmark start-0"></span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="btn-area mt-4 mt-sm-8">
                                                                                {
                                                                                    isCheck === true ?
                                                                                        <button className="box-style btn-box">
                                                                                            Sign Up
                                                                                        </button> :
                                                                                        <button className="box-style btn-box" disabled>
                                                                                            Sign Up
                                                                                        </button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sidebar-wrapper">
                    <div className="position-relative">
                        {/* <div className="side-menubar py-6 d-flex flex-column justify-content-between align-items-center">
                            <div className="sidebar-close d-none d-xl-block">
                                <button className="d-center d-grid">
                                    <i className="material-symbols-outlined mat-icon fs-three"> menu_open </i>
                                    <span>MENU</span>
                                </button>
                            </div>
                            <ul className="d-grid gap-4 social-area">
                                <li>
                                    <a href="https://www.facebook.com/" aria-label="Facebook" className="d-center">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" aria-label="Instagram" className="d-center">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" aria-label="Linkedin" className="d-center">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" aria-label="Twitter" className="d-center">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                            </ul>                            
                        </div> */}
                        <div className="sidebar-content d-center flex-columnn">
                            <div className="header-section d-block">
                                <div className="navbar bg-transparent">
                                    <ul className="navbar-nav d-xl-flex gap-2 gap-md-5 py-4 py-lg-0 px-4 px-lg-0 align-self-center mobile-dev">
                                        <li className={activeItem === 'home' ? 'active' : ''}>
                                            <a onClick={() => handleItemClick('home')} style={{ fontSize: "22px" }} className="dropdown-nav" href={"/"}>Home</a>
                                        </li>
                                        <li className={activeItem === 'store' ? 'active' : ''}>
                                            <a onClick={() => handleItemClick('store')} style={{ fontSize: "22px" }} className="dropdown-nav" href={"/store"}>Store</a>
                                        </li>
                                        <li className={activeItem === 'about' ? 'active' : ''}>
                                            <a onClick={() => handleItemClick('about')} style={{ fontSize: "22px" }} className="dropdown-nav" href="/about">About</a>
                                        </li>
                                        <li className={activeItem === 'contact' ? 'active' : ''}>
                                            <a onClick={() => handleItemClick('contact')} style={{ fontSize: "22px" }} className="dropdown-nav" href="#contact">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-items w-100 bottom-0">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Office</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> location_on </i>
                                                </div>
                                                <div className="right-item w-75">
                                                    <h5>London</h5>
                                                    <span>Al. Brucknera Aleksandra 63, Wrocław 51-410</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Email address</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    {/* <i className="material-symbols-outlined mat-icon fs-fure"> mail </i> */}
                                                </div>
                                                <div className="right-item d-grid">
                                                    <span><a href="https://pixner.net/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="d792afb6baa7bbb297b0bab6bebbf9b4b8ba">[email&#160;protected]</a></span>
                                                    <span><a href="https://pixner.net/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="591c21383429353c193e34383035773a3634">[email&#160;protected]</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Phone Number</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> call </i>
                                                </div>
                                                <div className="right-item d-grid">
                                                    <span>(302) 555-0107</span>
                                                    <span>(302) 555-0107</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Working Hours</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> schedule </i>
                                                </div>
                                                <div className="right-item w-50">
                                                    <span>Mon-Fri: 09: 00-18: 00 Sat-Sun: Weekend</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="banner-section inner-banner position-relative about">
                    <div className="shape-area">
                        <img src="assets/images/abs-items/bubble.png" className="shape-1" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-7.png" className="shape-2" alt="icon" />
                    </div>
                    <div className="container position-relative">
                        <div className="banner-content row justify-content-between">
                            <div className="col-lg-6 col-md-10">
                                <div className="main-content">
                                    <h2 className="visible-slowly-bottom display-four mb-6">Our privacy <span>policy</span></h2>
                                    <p className="fs-four">We're always looking for new ways to provide privacy for our
                                        customers.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10 d-grid align-items-end justify-content-end">
                                <div className="breadcrumb-area position-absolute end-0 bottom-0">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb m-0 py-3 px-8 fs-six d-flex align-items-end justify-content-end">
                                            <li className="breadcrumb-item p-0 m-0"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="/privacy-policy">Privacy Policy</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="privacy-content">
                    <div className="overlay pt-120">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="top-wrapper">
                                        <h4>We're always looking for new ways to provide privacy for our customers.</h4>
                                        <p>Id ipsum mi tempor eget. Pretium consectetur scelerisque blandit habitasse non
                                            ullamcorper enim, diam quam id et, tempus massa. Sed nam vulputate pellentesque
                                            quis. Varius a, nunc faucibus proin elementum id odio auctor.
                                            Nunc, suspendisse consequat libero, pharetra tellus vulputate auctor venenatis
                                            tortor non rhoncus at duis. Pharetra ipsum mauris integer sit feugiat.</p>
                                        <ul>
                                            <li>— Blandit dignissim nulla varius tristique a sed integer ut tempor.</li>
                                            <li>— Augue interdum semper bibendum amet sed.</li>
                                            <li>— Dis in at ultricies tortor sit tellus.</li>
                                            <li>— Habitant ornare aenean maecenas pretium</li>
                                        </ul>
                                        <p>Eget purus aenean sit risus. Arcu, aliquam eget et viverra risus purus. Commodo
                                            fames tristique dui pharetra elit aliquet morbi. Eget consectetur risus nunc
                                            lorem sit consequat aliquet. Dolor velit consectetur etiam scelerisque. Integer
                                            interdum sodales scelerisque diam massa quam sit quis. Sed et dui a nam
                                            pulvinar. Tristique justo, donec lectus vitae, cursus ligula ridiculus lacus,
                                            tincidunt. Diam dictumst faucibus dui aliquet aenean nascetur feugiat leo. Etiam
                                            dignissim orci dignissim vitae.</p>
                                        <div className="safe-data">
                                            <h4>Your data is safe with us, we will not share any information with external
                                                sources.</h4>
                                            <p>Blandit dignissim nulla varius tristique a sed integer ut tempor. Augue
                                                interdum semper bibendum amet sed. Dis in at ultricies tortor sit tellus.
                                                Habitant ornare aenean maecenas pretium, dui ullamcorper quis. Egestas
                                                viverra et id aliquet faucibus rhoncus a. Sollicitudin nisl nulla tempor
                                                pretium lorem at mauris faucibus pulvinar.</p>
                                            <ul>
                                                <li>Eget purus aenean sit risus. Arcu, aliquam eget et viverra risus purus.
                                                    Commodo fames tristique dui pharetra elit aliquet morbi.</li>
                                                <li>Eget consectetur risus nunc lorem sit consequat aliquet. Dolor velit
                                                    consectetur etiam scelerisque.</li>
                                                <li>Integer interdum sodales scelerisque diam massa quam sit quis. Sed et
                                                    dui a nam pulvinar. Tristique justo, donec lectus vitae, cursus ligula
                                                    ridiculus lacus, tincidunt.</li>
                                                <li>Diam dictumst faucibus dui aliquet aenean nascetur feugiat leo. Etiam
                                                    dignissim.</li>
                                                <li> Aliquam eget et viverra risus purus. Commodo fames tristique dui
                                                    pharetra elit aliquet morbi.</li>
                                            </ul>
                                        </div>
                                        <div className="cookies">
                                            <h4>Cookies and tracking</h4>
                                            <p>Consequat mauris nunc congue nisi. Cursus metus aliquam eleifend mi in nulla.
                                                Dignissim cras tincidunt lobortis feugiat vivamus. Blandit aliquam etiam
                                                erat velit. Sapien faucibus et molestie ac. Ornare aenean euismod elementum
                                                nisi quis eleifend quam adipiscing vitae. Id cursus metus aliquam eleifend
                                                mi in nulla posuere sollicitudin.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="instagram-post">
                    <div className="container-fluid pt-120">
                    </div>
                </section>

                <footer className="footer-section">
                    <div className="container">
                        <div className="footer-bottom py-8">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-6 text-center">
                                        <div className="copyright">
                                            <p>Copyright © {currentYear} <a href="#!">Gamestorm</a> - All Right Reserved</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <div className="copyright d-flex">
                                            <p><a href="/privacy-policy">Privacy Policy</a>
                                            <a href="/terms&condition">Terms & Conditions</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

 