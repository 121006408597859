import {Routes, Route} from "react-router-dom";
import Store from "./component/Store";
import Home from "./component/Home";
import Privacy from "./component/Privacy";
import About from './component/About';
import Terms from "./component/Terms&condition";
import Termservice from './component/Termsservices';
function App() {
  return (
    <>
    {/* <Navbar /> */} 

    <Routes >
      <Route path="/" element={ <Home /> } />
      <Route path="/store" element={ <Store /> } />
      <Route path="/about" element={ <About /> } />
      <Route path="/privacy-policy" element={ <Privacy /> } />
      <Route path="/terms&condition" element={ <Terms /> } />
      <Route path="/termservice" element={ <Termservice /> } />
    </Routes>
    </>
  );
}

export default App;
