import React from 'react';

const getRankSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const lastDigit = number % 10;
    const suffix = lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13 ? suffixes[lastDigit] : suffixes[0];
    return `${suffix}`;
};

const RankComponent = ({ rank }) => {
    const formattedRank = getRankSuffix(rank);

    return (
        <div>
            {rank}<sup>{`${formattedRank}`}</sup>
        </div>
    );
};

export default RankComponent;